import React from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import { Toaster } from "react-hot-toast";

// Importing assets (you'll need to add these to your project)
import appStoreBadge from "./assets/app_store_badge.svg";
import appScreenshot from "./assets/feature_graphic.png";
import lotusIcon from "./assets/icon_no_artboard.svg";
import playStoreBadge from "./assets/play_store_badge.png";

let colorPrimary: string = "#ce7c7c";
let colorSurface: string = "#fff3e0";

const AppWrapper = styled.div`
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  direction: rtl;
  background-color: ${colorSurface};
  min-height: 100vh;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Header = styled.header`
  background-color: ${colorSurface};
  font-weight: bold;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Example shadow */
`;

const Main = styled.main`
  flex: 1;
`;

const Logo = styled.img`
  height: 148px;
`;

const HeaderLogo = styled.img`
  height: 48px;
`;

const Nav = styled.nav`
  ul {
    list-style-type: none;
    display: flex;
    li {
      margin-left: 1rem;
      a {
        color: ${colorPrimary};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 2rem 0;
`;

const AppScreenshot = styled.img`
  max-width: 90vw;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  max-height: 30vh;
  border-radius: 2rem;
  margin: 1rem 0;
`;

const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  img {
    object-fit: contain; /* Preserve aspect ratio while fitting within the specified dimensions */
    width: 200px;
    max-width: 40vw;
  }
`;

const FeaturesSection = styled.section`
  background-color: ${colorSurface};
  padding: 4rem 1rem;
`;

const Feature = styled.div`
  margin-bottom: 2rem;
`;

const Footer = styled.footer`
  background-color: ${colorSurface};
  color: ${colorPrimary};
  padding: 1rem;
  text-align: center;
  a {
    color: ${colorPrimary};
`;

const App: React.FC = () => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default anchor behavior
    const id = event.currentTarget.getAttribute("target");
    switch (id) {
      case "features":
        window.scrollTo({ top: 0, behavior: "smooth" });
        toast("בקרוב..");
        break;
      case "philosophy":
        window.scrollTo({ top: 0, behavior: "smooth" });
        toast("בקרוב..");
        break;
      case "download":
        // if on android, open the play store link. if on ios, open the app store link
        if (navigator.userAgent.match(/Android/i)) {
          window.open(
            "https://play.google.com/store/apps/details?id=io.rosenpin.news"
          );
        }
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          window.open(
            "https://apps.apple.com/il/app/%D7%97%D7%93%D7%A9%D7%94-%D7%A2%D7%99%D7%AA%D7%95%D7%A0%D7%95%D7%AA-%D7%A7%D7%A9%D7%95%D7%91%D7%94/id6476049358?uo=2"
          );
        }
    }
  };

  return (
    <AppWrapper>
      <Header>
        <HeaderLogo src={lotusIcon} alt="החדשה" />
        <Nav>
          <ul>
            <li>
              <a href="#" target="features" onClick={handleClick}>
                תכונות
              </a>
            </li>
            <li>
              <a href="#" target="philosophy" onClick={handleClick}>
                פילוסופיה
              </a>
            </li>
            <li>
              <a href="#" target="download" onClick={handleClick}>
                הורדה
              </a>
            </li>
          </ul>
        </Nav>
      </Header>

      <Main>
        <HeroSection>
          <AppScreenshot src={appScreenshot} alt="App Screenshot" />
          <h1>החדשה - עיתונות קשובה</h1>
          <p>אפליקציית החדשות שרוצה שתשתמשו בה פחות</p>
          <DownloadButtons>
            <a href="https://apps.apple.com/il/app/%D7%97%D7%93%D7%A9%D7%94-%D7%A2%D7%99%D7%AA%D7%95%D7%A0%D7%95%D7%AA-%D7%A7%D7%A9%D7%95%D7%91%D7%94/id6476049358?uo=2">
              <img src={appStoreBadge} alt="Download on the App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=io.rosenpin.news">
              <img src={playStoreBadge} alt="Get it on Google Play" />
            </a>
          </DownloadButtons>
        </HeroSection>
        {/*   <FeaturesSection id="features">
        <h2>תכונות מרכזיות</h2>
        <Feature>
          <h3>AI-Curated News</h3>
          <p>3-4 כתבות חשובות ביום, נבחרות על ידי AI</p>
        </Feature>
        <Feature>
          <h3>No FOMO</h3>
          <p>ביטחון שאתם מעודכנים בלי להיות מחוברים כל היום</p>
        </Feature>
        <Feature>
          <h3>Mindful Journalism</h3>
          <p>צריכת חדשות מודעת ובריאה יותר</p>
        </Feature>
      </FeaturesSection>*/}
      </Main>

      <Spacer></Spacer>

      <Footer>
        <p>&copy; 2023 החדשה. כל הזכויות שמורות.</p>
        <a href="https://rosenpin.io/hadasha/terms/privacy.html">
          מדיניות פרטיות
        </a>{" "}
        | <a href="https://rosenpin.io/hadasha/terms/terms.html">תנאי שימוש</a>
      </Footer>
      <Toaster />
    </AppWrapper>
  );
};

export default App;
